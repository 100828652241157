export var apiColletions = {

   //privacy policy && terms and condition
   "terms_and_condition":"/terms_and_condition",
   
    //Common
    "get_countries": "/country_list",
    "get_provider_setting_detail": "/get_provider_setting_detail",
    "get_language_list":"/get_language_list",

    // country city
    "get_country_city_list":"/get_country_city_list",

    // Auth
    "login": "/providerslogin",
    "register": "/providerregister",
    "logout":"/providerlogout",
    "provider_social_login_web":"/provider_social_login_web",
    "verification":"/verification",

    // vehicle
    "typelist_selectedcountrycity": "/typelist_selectedcountrycity",
    "getfareestimate": "/getfareestimate",
    "get_nearby_provider": "/get_nearby_provider",
    "get_server_time" : "/get_server_time",
    "provider_add_vehicle":"/provider_add_vehicle",
    "get_provider_vehicle_list":"/get_provider_vehicle_list",
    "get_provider_vehicle_detail":"/get_provider_vehicle_detail",
    "provider_edit_vehicle_detail":"/provider_edit_vehicle_detail",
    "provider_update_vehicle_detail":"/provider_update_vehicle_detail",

    // Profile
    "get_provider_detail": "/get_provider_detail",
    "provider_update": "/providerupdatedetail",
    "forgot_password": "/forgotpassword",
    "get_otp":"/get_otp",
    "update_password":"/update_password",

    //change vehicle
    "change_current_vehicle":"/change_current_vehicle",
    
    // Document Upload
    "get_user_document": "/getuserdocument",
    "upload_user_document": "/uploaduserdocument",
    "upload_vehicle_document":'/upload_vehicle_document',
    "get_provider_document": "/getproviderdocument",
    "upload_provider_document": "/uploaddocument",


    // Payment
    "get_card_list": "/payments/cards",
    "select_card": "/payments/card_selection",
    "add_card": "/payments/addcard",
    "get_payment_gateway": "/get_payment_gateway",
    "get_stripe_add_card_intent": "/payments/get_stripe_add_card_intent",
    "get_stripe_payment_intent_wallet": "/payments/get_stripe_payment_intent",
    "send_paystack_required_detail": "/payments/send_paystack_required_detail",
    "add_wallet_amount": "/add_wallet_amount",
    "delete_card": "/payments/delete_card",
    "pay_stripe_intent_payment":"/pay_stripe_intent_payment",

    // Wallet History
    "get_wallet_history": "/get_wallet_history",

    //userhistory
    "providerhistory": "/providerhistory",
    "usertripdetail": "/usertripdetail",
    "usergettripstatus" : "/usergettripstatus",
    "get_provider_info" : "/get_provider_info",
    "user_submit_invoice" : "/user_submit_invoice",

    //apply_promo_code
    "apply_promo_code": "/apply_promo_code",

    //Create Trip
    "create_trip": "/createtrip",
    "canceltrip" : "/canceltrip",
    
     //walletstatus
    "change_user_wallet_status": "/change_user_wallet_status",

    //usegiverting
    "usergiverating":"/usergiverating",

    //getfuturetrip
    "getfuturetrip":"/getfuturetrip",

    //gettrippath
    "getgooglemappath":"/getgooglemappath",

    //referral_code
    "apply_provider_referral_code":"/apply_provider_referral_code",

    //delete_user
    "delete_provider":"/delete_provider",

    // earning
    "get_provider_daily_earning_detail":"/get_web_provider_weekly_earning_detail",
    "get_web_provider_weekly_earning_detail":"/get_web_provider_weekly_earning_detail",

    //bank detail
    "add_bank_detail":"/add_bank_detail",
    "get_bank_detail":"/get_bank_detail",
    "delete_bank_detail":"/delete_bank_detail",

    // excel sheet
    "provider_history_export_excel":"/provider_history_export_excel",
    "provider_earning_export_excel":"/provider_earning_export_excel"

}
